<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/mycourse/courseList?ccid=' + ccid }">课程签到</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/mycourse/signList?actId=' + actId }">签到活动</el-breadcrumb-item>

      <el-breadcrumb-item :to="{
        path: '/mycourse/signlinkList?actId=' + actId
      }">签到环节</el-breadcrumb-item>
      <el-breadcrumb-item>签到二维码</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="result-container">
      <el-card shadow="never" style="margin-top: 15px">
        <el-row :gutter="20" style="
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 0.5px solid #eee;
          ">
          <el-col :span="15">
            <span class="title">{{ courseName }}</span>
            <span class="className" style="margin-right: 15px; margin-left: 15px">
              名单:
              <el-tag type="warning">
                {{ className == 'undefined' ? '无名单' : className }}
              </el-tag>
            </span>
            <span class="linkType">
              签到环节: <el-tag>{{ linkType == 1 ? '签到' : '签退' }}</el-tag>
            </span>
          </el-col>
          <el-col :span="9" style="text-align: right">
            <span v-if="classNum == 0" style="font-size: 23px; font-weight: bold; margin-right: 40px">签到人数：{{ signCount
              }}</span>
            <span v-else style="font-size: 23px; font-weight: bold; margin-right: 40px">签到人数：{{ signCount }} / {{
              classNum }}</span>
            <span v-if="isShow && (signStopType == 0 || signStopType == -1)" class="title" style="color: #ed1c24">
              剩余:{{ min }}:{{ sec }}
            </span>
            <span v-if="status == 2 && (signStopType == 0 || signStopType == -1)" class="title" style="color: #ed1c24">
              签到已结束！
            </span>
            <span v-if="status == 0 && (signStopType == 0 || signStopType == -1)" class="title" style="color: #36a62a">
              签到未开始！
            </span>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10" style="height: 540px; padding-top: 30px">
            <!-- <el-button class="share" @click="gotoShareCode()" size="small" type="primary" v-clipboard:copy="sharelink"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError">生成二维码分享链接</el-button> -->
            <div style="text-align: center; margin-bottom: 10px;" v-if="status == 1 || signStopType == 1">
              <span class="randomnum">
                <span style="color:#409eff;font-weight:bold;font-size:25px;">
                  随机签到码:</span>
                <span style="font-size: 40px; font-weight: bolder;margin-left:10px" v-if="isshowrandomnum">{{ randomnum
                  }}</span>
              </span>
              <el-button type="success" plain size="mini" v-if="isshowrandomnum"
                @click="isshowrandomnum = false">隐藏</el-button>
              <el-button type="success" plain size="mini" v-if="!isshowrandomnum"
                @click="isshowrandomnum = true">显示</el-button>
            </div>
            <!-- <div class="randomnum">
              <el-button  @click="gotoShareCode()" size="small" type="primary" >生成随机签到码</el-button>
            </div> -->
            <div v-if="(status != 0 && status != 2) || signStopType == 1" class="qrcode" ref="qrCodeUrl"></div>
            <el-button class="share" @click="gotoShareCode()" size="small" type="primary">生成二维码分享链接</el-button>
          </el-col>
          <el-col :span="14" style="padding-top: 20px">
            <el-tabs type="border-card" class="box-card" style="overflow-y: auto; overflow-x: hidden" ref="cardContent">
              <el-tab-pane :label="linkType == 1 ? '已签到' : '已签退'">
                <!-- 已签到列表元素新增渐变  -->
                <transition-group>
                  <div style="
                      text-align: center;
                      width: 70px;
                      height: 90px;
                      float: left;
                    " v-for="item in listSign" :key="item.name">
                    <el-avatar :size="50" :src="item.avatar"></el-avatar>
                    <div>{{ item.name }}</div>
                  </div>
                </transition-group>
              </el-tab-pane>
              <el-tab-pane :label="linkType == 1 ? '未签到' : '未签退'">
                <!-- 未签到列表元素新增渐变  -->
                <transition-group>
                  <div style="
                      text-align: center;
                      width: 70px;
                      height: 90px;
                      float: left;
                    " v-for="item in unsignlist" :key="item.name">
                    <el-dropdown size="mini" style="cursor: pointer" @command="handleCommand2">
                      <el-avatar :size="50" :src="item.avatar"></el-avatar>
                      <div>{{ item.name }}</div>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command="composeValue2('未认证', item.name)">未认证</el-dropdown-item>
                        <el-dropdown-item :command="composeValue2('迟到', item.name)">迟到</el-dropdown-item>
                        <el-dropdown-item :command="composeValue2('请假', item.name)">请假</el-dropdown-item>
                        <el-dropdown-item :command="composeValue2('其他', item.name)">其他</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </transition-group>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import Cookies from 'js-cookie'
var qrcode
export default {
  name: 'signcode',
  components: {
    QRCode
  },
  data() {
    return {
      ccid: '',
      //是否展示随机签到码
      isshowrandomnum: true,
      //随机签到码
      randomnum: '',
      code: '',
      actId: '',
      lid: '',
      courseName: '',
      linkType: null,
      className: '',
      timer: null,
      finalTime: '',
      isEnd: false,
      min: '',
      sec: '',
      screenWidth: null,
      isShow: false,
      peopleCnt: 0,
      path: 'wss://xiangqian.club:8001/ws/asset',
      socket: '',
      status: '',
      //未签到的列表
      unsignlist: [],
      //已签到的列表
      listSign: [],
      classNum: 0,
      totalnum: 0,
      signStopType: 0,
      signCount: 0,
      sharelink: '',
      circleUrl:
        'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
    }
  },
  created() {
    this.screenWidth = document.body.clientWidth
    this.screenHeight = document.body.clientHeight
    this.finalTime = this.$route.query.EndTime
    this.status = this.$route.query.status
    this.lid = window.sessionStorage.getItem('lid')
    this.ccid = window.sessionStorage.getItem('ccid')
    this.cid = window.sessionStorage.getItem('cid').toString()
    this.actId = window.sessionStorage.getItem('actId')
    this.courseName = window.sessionStorage.getItem('CourseName')
    this.signStopType = window.sessionStorage.getItem('signStopType')
    this.linkType = window.sessionStorage.getItem('linkType')
    this.className = window.sessionStorage.getItem('className')
    this.getUpdateCode()
    this.getSignList()
    this.getunSignList()
    this.getrandomnum()
    if (this.cid != '' && this.cid != 'undefined' && this.cid != null) {
      // this.selectClassNum()
    }
  },
  mounted() {
    this.creatQrCode()
    this.updateCode()
    this.countdown()

    this.init()
  },
  destroyed() {
    clearInterval(this.timer)
    this.end()
    if (window.sessionStorage.getItem('socketConnection') == 1) {
      this.close()
      window.sessionStorage.setItem('socketConnection', 0)
    }
  },
  methods: {
    // socket连接
    init: function () {
      if (typeof WebSocket === 'undefined') {
        alert('您的浏览器不支持socket')
      } else if (window.sessionStorage.getItem('socketConnection') == 0) {
        // 实例化socket
        this.socket = new WebSocket(this.path)
        // 监听socket连接
        this.socket.onopen = this.open
        // 监听socket错误信息
        this.socket.onerror = this.error
        // 监听socket消息
        this.socket.onmessage = this.getMessage
      } else return
    },

    // 获取签到列表
    async getSignList() {
      const { data: res } = await this.$http.get('rec/resultTeacher', {
        params: {
          lid: this.lid
        }
      })
      if (res.code == 200) {
        this.classNum = res.data[4]
        this.className = res.data[3] || 'undefined'
        this.listSign = res.data[2]
        this.linkType = res.data[5]
        this.finalTime = res.data[7]
        this.status = res.data[8]
        // console.log('判断一下', this.status)
        this.signStopType = res.data[6]
        // this.listSign.reverse()
        this.signCount = this.listSign.length
        // this.totalnum += this.signCount
      } else {
        this.signlist = []
        this.signnum = 0
      }
      // const { data: res } = await this.$http.get('rec/resultTeacher', {
      //   params: {
      //     lid: this.lid,
      //   },
      // })
      //
      // if (res.code == 200) {
      //   console.log('list结果', res.data)
      //   this.signlist = res.data[2]
      //   this.signnum = res.data[2].length
      //   // this.totalnum += this.signnum
      //   if (!this.hasAdd) {
      //     this.totalnum += this.signnum
      //     this.hasAdd = true
      //   }
      // } else {
      //   this.signlist = []
      //   this.signnum = 0
      // }
    },
    //获取未签到列表
    async getunSignList() {
      const { data: res } = await this.$http.get('rec/resultTeacher2', {
        params: {
          lid: this.lid
        }
      })
      if (res.code == 200) {
        this.unsignlist = res.data
        this.unsignnum = res.data.length
        // this.totalnum += this.unsignnum
        // this.unsignlist.reverse()
      } else {
        this.unsignlist = []
        this.unsignnum = 0
      }
      // const { data: res } = await this.$http.get('rec/resultTeacher2', {
      //   params: {
      //     lid: this.lid,
      //   },
      // })
      // console.log('res=>', res)
      // if (res.code == 200) {
      //   console.log(res)
      //   if(res.data[2]){
      //     this.unsignlist = res.data
      //     this.unsignnum = res.data.length
      //     // this.totalnum += this.unsignnum
      //   }
      //   if (!this.hasAdd) {
      //     this.totalnum += this.unsignnum
      //     this.hasAdd = true
      //   } else {
      //   }
      //   this.histogramData.arrdata = [this.signnum, this.unsignnum]
      //
      //   // 收到了数据，开始渲染，其实也可以不要flag，因为已经实现了监听数据变化
      //   this.flag = true
      // } else {
      //   this.unsignlist = []
      //   this.unsignnum = 0
      //   if (!this.hasAdd) {
      //     this.totalnum += this.unsignnum
      //     this.hasAdd = true
      //   }
      //   this.histogramData.arrdata = [this.signnum, this.unsignnum]
      //   // 收到了数据，开始渲染，其实也可以不要flag，因为已经实现了监听数据变化
      //   this.flag = true
      // }
    },

    open: function () {
      console.log('在code的socket连接成功')
      window.sessionStorage.setItem('socketConnection', 1)
      this.socket.send(window.sessionStorage.getItem('uid'))
    },
    error: function () { },
    getMessage: async function (msg) {
      console.log('code有人签到了！！！！！！！', msg)
      if (msg.data != '连接成功') {
        this.getunSignList()
        this.listSign.unshift(JSON.parse(msg.data))
        this.signCount = this.listSign.length
      }
    },
    close: function () {
      window.sessionStorage.setItem('socketConnection', 0)
    },

    // 查询班级人数
    selectClassNum: async function () {
      const { data: res } = await this.$http.get('/class/selectClassNum', {
        params: {
          cid: this.cid
        }
      })
      if (res.code == 200) {
        this.classNum = res.data
      }
    },
    // 倒计时
    countdown: function () {
      const end = Date.parse(this.finalTime)
      const now = Date.parse(new Date())
      const msec = end - now
      let min = parseInt((msec / 1000 / 60) % 60)
      let sec = parseInt((msec / 1000) % 60)
      // 签到进行中
      if (this.status == 1) {
        if ((min > 0 && sec >= 0) || (min == 0 && sec > 0)) {
          this.isShow = true
        } else {
          this.isShow = false
        }
        if (min <= 0 && sec <= 0) {
          this.status = 2
        }
      }
      this.min = min > 9 ? min : '0' + min
      this.sec = sec > 9 ? sec : '0' + sec
      const that = this
      this.sct = setTimeout(function () {
        that.countdown()
      }, 1000)
    },
    end() {
      clearTimeout(this.sct) //清除
    },
    // 代签到
    async handleCommand2(command) {
      const { data: res } = await this.$http.get('/rec/recByTea2', {
        params: {
          lid: this.lid,
          name: command.name,
          state: command.state
        }
      })
      if (res.code == 200) {
        this.getSignList()
        this.getunSignList()
      }
    },
    updateCode() {
      var times = 0
      var that = this
      var i = setInterval(async function () {
        that.getSignList()
        that.getunSignList()
        that.signCount = that.listSign.length
        if (times == 0) {
          await that.getUpdateCode()
          //console.log("noew code",that.code)
          qrcode.makeCode(that.code)
        }
        times++
        if (times == 10) {
          times = 0
        }
      }, 1000)
      this.timer = i
    },
    composeValue2(item, row) {
      return {
        state: item,
        name: row
      }
    },
    creatQrCode() {
      qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.code,
        image: '/src/assets/default_head_circle.png',
        width: this.screenWidth * 0.23,
        height: this.screenWidth * 0.23,
        colorDark: '#222',
        colorLight: 'white',
        correctLevel: QRCode.CorrectLevel.H
      })
      this.qrcode = qrcode
    },
    // 实时获取二维码数据
    async getUpdateCode() {
      // 更新数据
      const { data: res } = await this.$http.get('/act/updateCode', {
        params: {
          lid: this.lid
        }
      })
      // 失败
      if (res.code != 200) {
        this.$message.error('获取信息失败')
        return false
      } else {
        this.code = res.data.code
      }
    },
    //生成随机签到码
    async getrandomnum() {
      const { data: res } = await this.$http.get('/act/getCode', {
        params: {
          lid: this.lid
        }
      })
      if (res.code == 200) {
        this.randomnum = res.data
      }
    },
    //前往分享链接地址
    gotoShareCode() {
      //console.log("token",sessionStorage.getItem("Authorization"))
      if (this.status === '1' || this.signStopType == 1) {
        // this.$router.push({
        //   path:'/share',
        //   query:{
        //     lid:this.lid,
        //     uid:this.uid,
        //     EndTime: this.finalTime,
        //     status: this.status,
        //     token:sessionStorage.getItem("Authorization"),
        //     className:this.title
        //   }
        // })
        this.sharelink = `https://xiangqian.club/#/share?lid=${this.lid}&uid=${this.uid}&signStopType=${this.signStopType}`
        let oInput = document.createElement('input')
        oInput.value = this.sharelink
        document.body.appendChild(oInput)
        oInput.select() // 选择对象
        document.execCommand('Copy') // 执行浏览器复制命令
        oInput.className = 'oInput'
        oInput.style.display = 'none'
        this.$message.success('复制成功')
        // this.$router.push(`/share?lid=${this.lid}&uid=${this.uid}&EndTime=${this.finalTime}&status=${this.status}&token=${sessionStorage.getItem("Authorization")}&className=${this.title}`)
      } else {
        this.$alert('当前签到活动不在对应时间段', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'info',
              message: `分享失败`
            })
          }
        })
      }
    },
    //分享复制成功
    onCopy(e) {
      this.$message({
        message: '复制成功',
        type: 'success'
      })
    },
    onError(e) {
      this.$message({
        message: '复制失败',
        type: 'error'
      })
    }
  }
}
</script>

<style scoped>
.title,
.className,
.linkType {
  text-align: center;
}

.randomnum {
  text-align: center;
  margin: 10px 10px;
}

.title {
  color: #39b54a;
  font-size: 25px;
}

.className,
.linkType {
  margin: 10px 0px;
}

.qrcode {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.box-card {
  height: 500px;
}

/* 列表元素新增渐变 */
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 1s ease;
}

.share {
  /* float: right; */
  display: block;
  margin: 30px auto 0;
}
</style>
